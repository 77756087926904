const { default: axios } = require("axios");

const API_KEY = process.env.NEXT_PUBLIC_STRAPI_API_KEY;
const axiosClient = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}/api`,
  headers: {
    Authorization: `Bearer ${API_KEY}`,
  },
});
//Content Category-toi hamaatai api-uud
const getCategory = () => axiosClient.get("/categories?populate=*");

const getContents = () =>
  axiosClient
    .get("/contents?sort[0]=createdAt:desc&populate=*")
    .then((resp) => resp.data.data)
    .catch((err) => {
      console.error("Error:", err);
      return [];
    });

const getCategoryList = () =>
  axiosClient
    .get("/categories?populate=*")
    .then((resp) => resp.data.data)
    .catch((err) => {
      console.error("Api Error:", err);
      return [];
    });

const getTags = () =>
  axiosClient
    .get(`/tags?populate=*`)
    .then((resp) => resp.data.data)
    .catch((err) => {
      console.error("Api Error:", err);
      return [];
    });

const getContentsByCategory = (category, page = 1, pageSize = 12) =>
  axiosClient
    .get(
      `/contents?filters[categories][name][$in]=${category}&populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}`
    )
    .then((resp) => resp.data)
    .catch((err) => {
      console.error("Api Error:", err);
      return { data: [], meta: { total: 0 } };
    });

const getContentById = (id) =>
  axiosClient.get("/contents/" + id + "?populate=*");

const getCategoryById = (id) =>
  axiosClient.get("/categories/" + id + "?populate=*");

const getSuggestions = (query) =>
  axiosClient
    .get(`/categories?filters[name][$contains]=${query}&populate=*`)
    .then((resp) => resp.data.data)
    .catch((err) => {
      console.error("Error:", err);
      return [];
    });

const getSuggestion = (query) =>
  axiosClient
    .get(`/categories?filters[name][$contains]=${query}&populate=*`)
    .then((resp) => resp.data.data)
    .catch((err) => {
      console.error("Error:", err);
      return [];
    });

const getContentsByFirstLetter = (letter) =>
  axiosClient
    .get(`/contents?filters[Title][$startsWith]=${letter}&populate=*`)
    .then((resp) => resp.data.data)
    .catch((err) => {
      console.error("Api Error:", err);
      return [];
    });

const getContentsBySort = () =>
  axiosClient
    .get("/contents?sort[0]=createdAt:desc&populate=*")
    .then((resp) => resp.data.data)
    .catch((err) => {
      console.error("Api Error:", err);
      return [];
    });
const getContentsByTag = (tag) =>
  axiosClient
    .get(
      `/contents?filters[tags][name][$eq]=${tag}&sort[0]=createdAt:desc&populate=*`
    )
    .then((resp) => resp.data.data)
    .catch((err) => {
      console.error("Api Error:", err);
      return [];
    });

const getContentsBySearch = (searchTerm) =>
  axiosClient
    .get(`/contents?filters[Title][$contains]=${searchTerm}&populate=*`)
    .then((resp) => resp.data.data)
    .catch((err) => {
      console.error("Api Error:", err);
      return [];
    });
const getContentBySlug = (slug) =>
  axiosClient
    .get(`/contents?filters[slug][$eq]=${slug}&populate=*`)
    .then((resp) => resp.data.data[0])
    .catch((err) => {
      console.error("Error:", err);
      return null;
    });

export default {
  getCategory,
  getContents,
  getCategoryList,
  getContentsByCategory,
  getTags,
  getContentById,
  getCategoryById,
  getSuggestions,
  getContentsByFirstLetter,
  getContentsBySort,
  getContentsBySearch,
  getContentsByTag,
  getSuggestion,
  getContentBySlug,
};
