"use client";
import React, { useState, useEffect } from "react";
import GlobalApi from "@/utils/GlobalApi";
import CategoryList from "@/components/CategoryList";
import Home from "@/components/Home";

export default function Index() {
  const [categoryList, setCategoryList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchCategoryList = async () => {
      const data = await GlobalApi.getCategoryList();
      setCategoryList(data);
    };
    fetchCategoryList();
  }, []);

  return (
    <>
      <main className="scroll-smooth">
        <Home searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <CategoryList
          categoryList={categoryList.filter((category) =>
            category?.attributes?.name
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase())
          )}
        />
      </main>
    </>
  );
}
